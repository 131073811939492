var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('h2',[_vm._v(_vm._s(_vm.$route.params.ship))]),(_vm.ship)?_c('div',[_c('p',{staticStyle:{"white-space":"normal","word-wrap":"break-word"}},[_vm._v(" "+_vm._s(_vm.ship.description)+" ")])]):(_vm.error)?_c('div'):_c('div',[_c('v-skeleton-loader',{attrs:{"type":"text","width":"75em"}})],1),_c('v-btn',{attrs:{"to":"/ships"}},[_vm._v(" Return to index ")]),(_vm.error)?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):(_vm.ship && _vm.stats.weapons)?_c('div',[_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('DataCard',{attrs:{"title":"Speed","data":[
            ['Top Speed', _vm.ship.speed.top],
            ['Turn Speed', _vm.ship.speed.turn.toFixed(2)],
            ['Acceleration', _vm.ship.speed.acceleration],
            ['Custom Drift', _vm.ship.customDrift || undefined]
          ]}})],1),_c('v-col',[_c('DataCard',{attrs:{"title":"Health","data":[
            ['Shield', _vm.ship.health.shield],
            ['Hull', _vm.ship.health.hull],
            ['Combined', _vm.ship.health.shield + _vm.ship.health.hull],
            ['Resistance', `${Math.round(_vm.ship.resistance * 100)}%`]
          ]}})],1),_c('v-col',[_c('DataCard',{attrs:{"title":"Other","data":[
            ['Class', _vm.ship.class],
            ['Cargo Hold', _vm.ship.cargoHold],
            ['Ore Hold', _vm.ship.oreHold],
            ['Event ID', _vm.ship.eventId || undefined],
            ['Permit', _vm.ship.permit || undefined],
            ['Explosion Size', _vm.ship.explosionSize],
            ['Not For Sale', _vm.ship.notForSale || undefined],
            ['Secret', _vm.ship.secret || undefined],
            ['Warp Drive', _vm.ship.canWarp],
            ['Stealth', _vm.ship.stealth || undefined],
            ['Requires VIP', _vm.ship.vip || undefined]
          ]}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('h3',[_vm._v("Weaponry")]),_c('v-row',{staticClass:"mt-2"},[_c('v-col',[_c('v-slider',{staticClass:"align-center",attrs:{"min":0,"max":12000,"step":250,"label":`Range: ${_vm.range}`},model:{value:(_vm.range),callback:function ($$v) {_vm.range=$$v},expression:"range"}})],1),_c('v-col',[_c('v-slider',{staticClass:"align-center",attrs:{"min":0,"max":33,"label":`Loyalty: ${_vm.loyalty}%`},model:{value:(_vm.loyalty),callback:function ($$v) {_vm.loyalty=$$v},expression:"loyalty"}})],1)],1),_c('Weapons',{attrs:{"weapons":_vm.stats.weapons.total}}),_c('h4',[_vm._v("Turrets")]),_c('Weapons',{attrs:{"weapons":_vm.stats.weapons.turrets}}),_c('h4',[_vm._v("Spinals")]),_c('Weapons',{attrs:{"weapons":_vm.stats.weapons.spinals}})],1)],1)],1)],1):_c('div',[_c('v-progress-circular',{staticClass:"mb-8",attrs:{"indeterminate":"","color":"primary"}})],1),(!_vm.error && _vm.disableKills !== 'true')?_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('h3',[_vm._v("Recent Kills")]),_c('RecentKills',{attrs:{"killer-ship":_vm.$route.params.ship}})],1)],1),_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('h3',[_vm._v("Recent Deaths")]),_c('RecentKills',{attrs:{"victim-ship":_vm.$route.params.ship}})],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }